import { getLocations } from '@vakantiesnl/components/src/utils/geo/getLocations';
import { getMicroCopy } from '@vakantiesnl/services/src/microcopy';
import { Nbc, Search } from '@vakantiesnl/types';

import data from '@MicroCopiesNL';

import { mapOffers } from './mapper';
import {
	mapContactInformation,
	mapDescription,
	mapFacts,
	mapGeoCoordinates,
	mapMerchandisingLabel,
	mapNbcImages,
	mapTopImages,
} from '../nbc/mapper';

export function mapCompactAccommodations(raw: Search.RawCompactAccommodationItem[]): Search.CompactAccommodationItem[] {
	return raw.map(mapCompactAccommodation);
}

export function mapCompactAccommodation(raw: Search.RawCompactAccommodationItem): Search.CompactAccommodationItem {
	return {
		id: raw.nbc?.accommodation?.id ?? '',
		giataId: raw.giata_id,
		heliosId: raw.nbc?.accommodation?.entity_id ?? 0,
		goalId: raw.nbc?.accommodation?.goal_id ?? null,
		slug: raw.nbc?.accommodation?.slug ?? '',
		fullSlugWithType: `/${raw.nbc?.accommodation?.full_slug_with_acco_type}`,
		name: raw.nbc?.accommodation?.name ?? '',
		title: raw.nbc?.accommodation?.name ?? '',
		isBookable: raw.nbc?.accommodation?.is_bookable ?? false,
		isBookableQenner: Boolean(raw.nbc?.accommodation?.is_bookable_qenner),
		starRating: Math.round(raw.nbc?.accommodation?.star_rating ?? 0),
		rating: Math.round((raw.nbc?.accommodation?.review_calculations?.overall?.rating ?? 0) * 10) / 10,
		reviewCount: raw.nbc?.accommodation?.review_count ?? 0,
		reviewPerAspectGroup: raw.nbc?.accommodation?.review_calculations?.perAspectGroup ?? {},
		country: raw.nbc?.accommodation?.country ?? '',
		region: raw.nbc?.accommodation?.region ?? '',
		city: raw.nbc?.accommodation?.city ?? '',
		type: raw.nbc?.accommodation?.acco_type ?? '',
		formattedType: getMicroCopy(`accoType.${raw.nbc?.accommodation?.acco_type}`, data),
		merchandisingLabel: raw.nbc?.accommodation?.merchandising_label
			? mapMerchandisingLabel(raw.nbc.accommodation.merchandising_label)
			: undefined,
		previewImage: raw.nbc?.accommodation?.preview_image ? raw.nbc.accommodation.preview_image : undefined,
		topImages: mapTopImages(raw.nbc?.accommodation?.top_images ?? []),
		awards: raw.nbc?.accommodation?.awards ?? {},
		parents: raw.nbc?.accommodation?.parents ?? [],
		location: getLocations(raw.nbc?.accommodation?.parents),
		offers: mapOffers(raw.offers ?? []),
		chain: raw.nbc?.accommodation?.chain ?? null,
	};
}

export function mapFullAccommodations(raw: Search.RawFullAccommodationItem[]): Search.FullAccommodationItem[] {
	return raw.map(mapFullAccommodation);
}

export function mapFullAccommodation(raw: Search.RawFullAccommodationItem): Search.FullAccommodationItem {
	return {
		...mapCompactAccommodation(raw),
		contactInformation: mapContactInformation(raw.nbc as Nbc.RawFullNbcItem),
		description: mapDescription(raw.nbc?.accommodation?.description),
		distanceFilters: raw.nbc?.accommodation?.distance_filters ?? [],
		facts: raw.nbc ? mapFacts(raw.nbc) : undefined,
		filters: raw.nbc?.accommodation?.filters ?? [],
		geo: mapGeoCoordinates(raw.nbc as Nbc.RawFullNbcItem),
		countryCode: raw.nbc?.accommodation?.country_code ?? '',
		images: (raw.nbc?.accommodation?.images ?? []).map(mapNbcImages),
		premiumAdlinkEnabled: raw.nbc?.accommodation?.premium_ad_link_enabled ?? false,
		premiumAdlinkUrl: raw.nbc?.accommodation?.premium_ad_link_url ?? null,
		source: raw.nbc?.accommodation?.source,
	};
}
